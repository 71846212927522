import React, { Fragment } from "react";
import { ThemeProvider } from "styled-components";
import { Modal } from "@redq/reuse-modal";
import Sticky from "react-stickynode";
import { DrawerProvider } from "common/contexts/DrawerContext";
import { saasClassicTheme } from "common/theme/saasClassic";
import { ResetCSS } from "common/assets/css/style";
import {
  GlobalStyle,
  ContentWrapper,
} from "containers/SaasClassic/saasClassic.style";
import { bitCheck } from "../common/components/payment";

import Navbar from "Containers/Navbar";
import InstractionSteps from "../Containers/InstractionSteps";
import "@redq/reuse-modal/es/index.css";
import Seo from "components/seo";
/* import firebaseApp from "../common/components/firebase.js";
import {
  onSnapshot,
  getFirestore,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore"; */

import Footer from "Containers/Footer";

const PaymentReturn = ({ location }) => {
  const [coupon, setCoupon] = React.useState(null);
  const [lang, setLang] = React.useState("He");
  const [aCoupon, setAcoupon] = React.useState(null);
  //const { thumb, title, features } = data.appCreativeJson.chooseUs;

  const createCoupon = (t_id) => {
    const current = new Date();
    const newCoupon = `${t_id.substring(5, 8)}${current.getMonth()}${
      (current.getDate() + current.getMonth() + current.getFullYear() + 7) % 5
    }`;

    return newCoupon;
  };

  React.useEffect(() => {
    const params = new URLSearchParams(location.search);

    const t_id = params.get("tID");
    const bCheck = params.get("bitCheck");
    const wantLang = params.get("lang");
    let source = params.get("source");
    let guideId = params.get("guideId");
    let totalA = params.get("totalA");
    const calcBitCheck = bitCheck(t_id);
    console.log("calcBit", calcBitCheck);

    if (calcBitCheck === Number(bCheck)) {
      if (!source) {
        source = "Dguide";
      }
      console.log("BEFOR FETCH TO ADD PURCASE");
      setCoupon(createCoupon(t_id));
      fetch(
        `https://europe-west1-tours-app-1579553856346.cloudfunctions.net/newPurchase/?tourID=${t_id}&coupon=${coupon}&source=${source}&deviceID=${bCheck}&guideId=${guideId}&totalA=${totalA}`
      )
        .then((response) => console.log("RESPONS", response))
        .catch(function (err) {
          console.log("Fetch Error :-S", err);
        });
    }
  }, [location.search]);

  React.useEffect(() => {
    const params = new URLSearchParams(location.search);
    const t_id = params.get("tID");
    const bCheck = params.get("bitCheck");
    const wantLang = params.get("lang");

    const calcBitCheck = bitCheck(t_id);
    if (wantLang && (wantLang === "He" || wantLang === "En")) setLang(wantLang);
    if (calcBitCheck === Number(bCheck)) {
      setCoupon(createCoupon(t_id));
    }
    setAcoupon(params.get("aCoupon"));
  }, []);

  return (
    <ThemeProvider theme={saasClassicTheme}>
      <Fragment>
        <Seo
          title={`Dguide | Purchase page}`}
          description={"אישור תשלום"}
          lang={"He"}
          meta={[]}
          keywords={[]}
        />
        <Modal />
        <ResetCSS />
        <GlobalStyle />

        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar returnPayment={true} />
            </DrawerProvider>
          </Sticky>
          <InstractionSteps coupon={aCoupon} lang={lang} />
          <Footer />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};
export default PaymentReturn;
